import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`First step `}<em parentName="h5">{`MVP`}</em></h5>
    <p>{`This is still a work in progress idea and I first wanted to create a page with some minimal required
features.`}</p>
    <p>{`The page needs to include:`}</p>
    <ol>
      <li parentName="ol">{`Generating the random artwork`}</li>
      <li parentName="ol">{`Showing the artwork to the user`}</li>
      <li parentName="ol">{`Have a button to regenerate`}</li>
      <li parentName="ol">{`Have a button to save the artwork`}</li>
    </ol>
    <p>{`You can see the still in progress implementation of the `}<strong parentName="p">{`artwork generator`}</strong>{`
`}<a parentName="p" {...{
        "href": "https://artwork-generator.litc0.de"
      }}>{`here`}</a>{`.`}</p>
    <p>{`On the next pages you can see some `}<strong parentName="p">{`screenshots`}</strong>{` of the website and two `}<strong parentName="p">{`generated artworks`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      